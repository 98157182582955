import { setKey, fromAddress, geocode } from "react-geocode";
import { toast } from "react-toastify";
import { scoreMagicMinMaxValues } from "../pages/organizer/forms/formSelectArrays";

import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
setKey(process.env.REACT_APP_GEOCODE_API_KEY);

export function CalculateHeadToHeadFilteredStats(
  shouldCalculate,
  gridRef,
  setFiltered
) {
  if (shouldCalculate) {
    let filteredStats = {
      inFourTotal: 0,
      inTotal: 0,
      offTotal: 0,
      onTotal: 0,
      totalRounds: 0,
      totalLosses: 0,
      totalPointsAgainst: 0,
      totalPointsFor: 0,
      totalNetPointsAgainst: 0,
      totalNetPointsFor: 0,
      totalTies: 0,
      totalWins: 0,
    };
    console.log(
      "gridRef.current.api.getDisplayedRowCount(): ",
      gridRef.current.api.getDisplayedRowCount()
    );
    //If there's no filtered rows, set all to 0
    if (gridRef.current.api.getDisplayedRowCount() === 0) {
      setFiltered(filteredStats);
    }
    //allNodes array is just for console log reference. Not being used anywhere
    let allNodes = [];
    gridRef.current.api.forEachNodeAfterFilter((node) => {
      //node.data
      const matchup = node.data;
      console.log("node: ", node);
      console.log("matchup: ", matchup);
      allNodes.push(matchup);
      const nodeStatsObj = {
        totalLosses:
          matchup.outcome === 0
            ? filteredStats.totalLosses + 1
            : filteredStats.totalLosses,
        totalNetPointsAgainst:
          filteredStats.totalNetPointsAgainst + matchup.totalNetPointsAgainst,
        totalPointsAgainst:
          filteredStats.totalPointsAgainst + matchup.totalPointsAgainst,
        totalPointsFor:
          filteredStats.totalPointsFor + Number(matchup.totalPointsFor),
        totalNetPointsFor:
          filteredStats.totalNetPointsFor + Number(matchup.totalNetPointsFor),
        totalTies:
          matchup.outcome === 2
            ? filteredStats.totalTies + 1
            : filteredStats.totalTies,
        totalWins:
          matchup.outcome === 1
            ? filteredStats.totalWins + 1
            : filteredStats.totalWins,
        inFourTotal: filteredStats.inFourTotal + matchup.inFourTotal,
        inTotal: filteredStats.inTotal + matchup.inTotal,
        offTotal: filteredStats.offTotal + matchup.offTotal,
        onTotal: filteredStats.onTotal + matchup.onTotal,
        totalRounds: filteredStats.totalRounds + matchup.totalRounds,
      };
      filteredStats = nodeStatsObj;
      console.log("nodeStatsObj: ", nodeStatsObj);
    });
    function GetStatPercentage(stat, bags) {
      const newValue = Math.round(
        (stat /
          (bags ? filteredStats.totalRounds * 4 : filteredStats.totalRounds)) *
          100
      );
      if (Number.isNaN(newValue)) {
        return 0;
      } else {
        return newValue;
      }
    }
    const calculatedScoremagicValues = {
      inFourPercent: GetStatPercentage(filteredStats.inFourTotal),
      inPercent: GetStatPercentage(filteredStats.inTotal, true),
      offPercent: GetStatPercentage(filteredStats.offTotal, true),
      onPercent: GetStatPercentage(filteredStats.onTotal, true),
      oppr: GetStatPercentage(filteredStats.totalPointsAgainst) / 100,
      ppr: GetStatPercentage(filteredStats.totalPointsFor) / 100,
      dpr:
        Math.round(
          (GetStatPercentage(filteredStats.totalPointsFor) / 100 -
            GetStatPercentage(filteredStats.totalPointsAgainst) / 100) *
            100
        ) / 100,
    };
    console.log("filteredStats: ", filteredStats);
    console.log("calculatedScoremagicValues: ", calculatedScoremagicValues);
    console.log("allNodes: ", allNodes);
    setFiltered({
      ...filteredStats,
      ...calculatedScoremagicValues,
    });
    console.log("SHOULD HAVE SET FILTERED");
  } else {
    setFiltered(null);
  }
}

export function ConvertWYSIWYGData(data) {
    const blocksFromHTML = convertFromHTML(data);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );

    return EditorState.createWithContent(contentState);
  }

export function CalculateGhostholioFilteredStats(
  shouldCalculate,
  gridRef,
  setFiltered
) {
  if (shouldCalculate) {
    let filteredStats = {
      totalFourIn: 0,
      totalIn: 0,
      totalOff: 0,
      totalOn: 0,
      totalPoints: 0,
      bestGhost: 0,
      bestGhostLevel: "None",
      totalRounds: 0,
    };
    console.log(
      "gridRef.current.api.getDisplayedRowCount(): ",
      gridRef.current.api.getDisplayedRowCount()
    );
    console.log("gridRef.current ", gridRef.current);
    //allNodes array is just for console log reference. Not being used anywhere
    let allNodes = [];
    function CheckDifficultyLevel(outcome, level) {
      let ghostLevel = "Easy";
      switch (level) {
        case 0:
          ghostLevel = "Easy";
          break;
        case 1:
          ghostLevel = "Ben";
          break;
        case 2:
          ghostLevel = "Medium";
          break;
        case 3:
          ghostLevel = "Hard";
          break;
        case 4:
          ghostLevel = "Whittmart";
          break;
        case 5:
          ghostLevel = "Expert";
          break;
        default:
          ghostLevel = "Easy";
          break;
      }
      return ghostLevel;
    }
    //If there's no filtered rows, set all to 0
    console.log(
      "gridRef.current.api.getDisplayedRowCount(): ",
      gridRef.current.api.getDisplayedRowCount()
    );
    if (gridRef.current.api.getDisplayedRowCount() === 0) {
      setFiltered(filteredStats);
    }
    gridRef.current.api.forEachNodeAfterFilter((node, index) => {
      //node.data
      console.log("node: ", node);
      console.log("index: ", index);

      if (
        // index === 0
        (index === 0 && !node.childrenAfterFilter) ||
        (index > 0 && !node.data)
      ) {
        console.log("SET FILTERED TO 0");
        setFiltered(filteredStats);
      } else {
        const matchup =
          index === 0 || !node.hasOwnProperty("data")
            ? node.childrenAfterFilter[index].data
            : node.data;
        console.log("matchup: ", matchup);
        if (
          matchup &&
          !allNodes.find((e) => e.practiceid === matchup.practiceid)
        ) {
          const matchup =
            index === 0 ? node.childrenAfterFilter[index].data : node.data;
          console.log("matchup: ", matchup);
          allNodes.push(matchup);
          const nodeStatsObj = {
            totalFourIn: filteredStats.totalFourIn + matchup.totalfourbagger,
            totalIn: filteredStats.totalIn + matchup.totalin,
            totalOff: filteredStats.totalOff + matchup.totaloff,
            totalOn: filteredStats.totalOn + matchup.totalon,
            totalPoints: filteredStats.totalPoints + matchup.totalscore,
            totalRounds: filteredStats.totalRounds + matchup.totalrounds,
            bestGhostLevel:
              matchup.outcome === 1
                ? CheckDifficultyLevel()
                : filteredStats.bestGhostLevel,
            bestGhost:
              matchup.outcome === 1
                ? Math.round(matchup.avgopp * 100) / 100
                : filteredStats.bestGhost,
          };
          filteredStats = nodeStatsObj;
          console.log("nodeStatsObj: ", nodeStatsObj);
        }
      }
    });

    function GetStatPercentage(stat, bags) {
      const newValue = Math.round(
        (stat /
          (bags ? filteredStats.totalRounds * 4 : filteredStats.totalRounds)) *
          100
      );
      if (Number.isNaN(newValue)) {
        return 0;
      } else {
        return newValue;
      }
    }
    const calculatedScoremagicValues = {
      avgPpr: GetStatPercentage(filteredStats.totalPoints) / 100,
      totalFourInPercent: GetStatPercentage(filteredStats.totalFourIn),
      totalInPercent: GetStatPercentage(filteredStats.totalIn, true),
      totalOffPercent: GetStatPercentage(filteredStats.totalOff, true),
      totalOnPercent: GetStatPercentage(filteredStats.totalOn, true),
    };
    console.log("filteredStats: ", filteredStats);
    console.log("calculatedScoremagicValues: ", calculatedScoremagicValues);
    console.log("allNodes: ", allNodes);
    setFiltered({
      ...filteredStats,
      ...calculatedScoremagicValues,
    });
  } else {
    setFiltered(null);
  }
}

export function CalculateDecaholioFilteredStats(
  shouldCalculate,
  gridRef,
  setFiltered
) {
  if (shouldCalculate) {
    let filteredStats = {
      totalFourIn: 0,
      totalIn: 0,
      totalOff: 0,
      totalOn: 0,
      totalPoints: 0,
      bestRound: 0,
      totalRounds: 0,
    };
    console.log(
      "gridRef.current.api.getDisplayedRowCount(): ",
      gridRef.current.api.getDisplayedRowCount()
    );
    console.log("gridRef.current ", gridRef.current);
    //allNodes array is just for console log reference. Not being used anywhere
    let allNodes = [];
    //If there's no filtered rows, set all to 0
    if (gridRef.current.api.getDisplayedRowCount() === 0) {
      setFiltered(filteredStats);
    }
    gridRef.current.api.forEachNodeAfterFilter((node, index) => {
      //node.data
      console.log("index: ", index);
      console.log("node: ", node);
      console.log(
        "index === 0 && !node.childrenAfterFilter: ",
        index === 0 && !node.childrenAfterFilter
      );
      console.log("index > 0 && !node.data: ", index > 0 && !node.data);

      if (
        // index === 0
        (index === 0 && !node.childrenAfterFilter) ||
        (index > 0 && !node.data)
      ) {
        console.log("SET FILTERED TO 0");
        setFiltered(filteredStats);
      } else {
        const matchup =
          index === 0 || !node.hasOwnProperty("data")
            ? node.childrenAfterFilter[index].data
            : node.data;
        console.log("matchup: ", matchup);
        if (
          matchup &&
          !allNodes.find((e) => e.practiceid === matchup.practiceid)
        ) {
          console.log("setting nodeStatsObj from matchup");
          allNodes.push(matchup);
          const nodeStatsObj = {
            totalFourIn: filteredStats.totalFourIn + matchup.totalfourbagger,
            totalIn: filteredStats.totalIn + matchup.totalin,
            totalOff: filteredStats.totalOff + matchup.totaloff,
            totalOn: filteredStats.totalOn + matchup.totalon,
            totalPoints: filteredStats.totalPoints + matchup.totalscore,
            totalRounds: filteredStats.totalRounds + matchup.totalrounds,
            bestRound:
              filteredStats.bestRound < matchup.overallscoreteam1
                ? matchup.overallscoreteam1
                : filteredStats.bestRound,
          };
          filteredStats = nodeStatsObj;
          console.log("nodeStatsObj: ", nodeStatsObj);
        }
      }
    });
    console.log("allNodes: ", allNodes);
    function GetStatPercentage(stat, bags) {
      const newValue = Math.round(
        (stat /
          (bags ? filteredStats.totalRounds * 4 : filteredStats.totalRounds)) *
          100
      );
      if (Number.isNaN(newValue)) {
        return 0;
      } else {
        return newValue;
      }
    }
    const calculatedScoremagicValues = {
      avgPpr: GetStatPercentage(filteredStats.totalPoints) / 100,
      totalFourInPercent: GetStatPercentage(filteredStats.totalFourIn),
      totalInPercent: GetStatPercentage(filteredStats.totalIn, true),
      totalOffPercent: GetStatPercentage(filteredStats.totalOff, true),
      totalOnPercent: GetStatPercentage(filteredStats.totalOn, true),
    };
    console.log("filteredStats: ", filteredStats);
    console.log("calculatedScoremagicValues: ", calculatedScoremagicValues);
    console.log("allNodes: ", allNodes);
    setFiltered({
      ...filteredStats,
      ...calculatedScoremagicValues,
    });
  } else {
    setFiltered(null);
  }
}

export function RefreshPlayerProfileURL() {
  try {
    window.Android.refreshPlayerProfileURL();
  } catch (err) {
    console.log("android refreshPlayerProfileURL fail");
  }
  try {
    window.webkit.messageHandlers.refreshPlayerProfileURL.postMessage(
      "refreshPlayerProfileURL"
    );
  } catch (err) {
    console.log("webkit refreshPlayerProfileURL fail");
  }
}

export function SetEventColor(opacity, teamcreate, pastelRed) {
  let color = "#fff";
  switch (teamcreate) {
    case "Singles":
      color = `#d07a2d` + `${opacity}`;
      break;
    case "Blind Draw":
      color = `#43922a` + `${opacity}`;
      break;
    case "Set Teams":
      color = `#3576a4` + `${opacity}`;
      break;
    case "Switcholio":
      color = `#4b3179` + `${opacity}`;
      break;
    case "Split Switch":
      color = pastelRed ? "#e58586" : `#CC0C0D` + `${opacity}`;
      break;
    case "Squadholio":
      color = `#bb9b34` + `${opacity}`;
      break;
    case "MultiMatch":
      color = `#5D3911` + `${opacity}`;
      break;
    default:
      color = `#333` + `${opacity}`;
      break;
  }
  return color;
}

export function FieldTerminology(sport, singular) {
  let courtTerminology = "Courts";
  let maxScoreTerminology = "Score Limit";
  switch (sport) {
    case "axethrowing":
      courtTerminology = singular ? "Lane" : "Lanes";
      maxScoreTerminology = "Score Limit";
      break;
    case "darts":
      courtTerminology = singular ? "Board" : "Boards";
      maxScoreTerminology = "Best Of";
      break;
    case "billiards":
      courtTerminology = singular ? "Table" : "Tables";
      maxScoreTerminology = "Race To";
      break;
    case ("pong", "tableshuffleboard", "tabletennis", "beerpong"):
      courtTerminology = singular ? "Table" : "Tables";
      maxScoreTerminology = "Game To";
      break;
    case ("tennis", "volleyball", "pickleball", "other", "bocce", "21bust", 'popdarts'):
      courtTerminology = singular ? "Court" : "Courts";
      maxScoreTerminology = "Game To";
      break;
    case "horseshoes":
      courtTerminology = singular ? "Pit" : "Pits";
      maxScoreTerminology = "Game To";
      break;
    default:
      //Cornhole or anything else
      courtTerminology = singular ? "Court" : "Courts";
      maxScoreTerminology = "Score Limit";
  }
  return { courtTerminology, maxScoreTerminology };
}

export function OpenExternalURL(url, platform) {
  console.log("openexternalurl: ", url, platform);
  if (platform) {
    try {
      try {
        window.Android.openExternalURL(url);
      } catch (err) {
        console.log("android fail openExternalURL");
      }
      try {
        window.webkit.messageHandlers.openExternalURL.postMessage(url);
      } catch (err) {
        console.log("webkit fail openExternalURL");
      }
    } catch (err) {
      console.log("OpenExternalURL error: ", err);
      const win = window.open(url, "_blank");
      win.focus();
    }
  } else {
    const win = window.open(url, "_blank");
    win.focus();
  }
}

export function ordinal_suffix_of(i) {
  var j = i % 10,
    k = i % 100;
  if (j == 1 && k != 11) {
    return i + "st";
  }
  if (j == 2 && k != 12) {
    return i + "nd";
  }
  if (j == 3 && k != 13) {
    return i + "rd";
  }
  return i + "th";
}

export function LaunchDash(platform, player, url) {
  console.log("LaunchDash platform, player, url: ", platform, player, url);
  if (platform || player.build > 0) {
    try {
      try {
        window.Android.launchDash(url);
      } catch (err) {
        console.log("android fail");
      }
      try {
        let appdata = {};
        appdata.gameDashUrl = url;
        window.webkit.messageHandlers.launchDash.postMessage(
          JSON.stringify(appdata)
        );
      } catch (err) {
        console.log("webkit fail");
      }
    } catch (err) {
      console.log("LaunchUrl error: ", err);
      const win = window.open(url, "_blank");
      https: win.focus();
    }
  } else {
    const win = window.open(url, "_blank");
    https: win.focus();
  }
}

export function LaunchPromo(platform, player, url, title) {
  console.log("LaunchPromo platform, player, url: ", platform, player, url);
  if (platform || player.build > 0) {
    try {
      try {
        window.Android.launchPromo(url, title);
      } catch (err) {
        console.log("android fail");
      }
      try {
        let appdata = {};
        appdata.gameDashUrl = url;
        appdata.gameDashTitle = title;
        window.webkit.messageHandlers.launchPromo.postMessage(
          JSON.stringify(appdata)
        );
      } catch (err) {
        console.log("webkit fail");
      }
    } catch (err) {
      console.log("LaunchUrl error: ", err);
      const win = window.open(url, "_blank");
      https: win.focus();
    }
  } else {
    const win = window.open(url, "_blank");
    https: win.focus();
  }
}

export function OpenUpgrade(platform, navType, toast) {
  if (!platform && navType === "organizer") {
    toast.error("Please upgrade in app!", {
      autoClose: 5000,
      hideProgressBar: true,
    });
  } else {
    try {
      window.Android.openUpgrade();
    } catch (err) {
      console.log("android fail");
    }
    try {
      window.webkit.messageHandlers.openUpgrade.postMessage(
        '{"status":"upgrade"}'
      );
    } catch (err) {
      console.log("webkit fail");
    }
  }
}

export function SetCustomTheme(platform, type) {
  if (platform || platform === "none") {
    try {
      window.Android.setCustomTheme(type);
    } catch (err) {
      console.log("android fail");
    }
    try {
      window.webkit.messageHandlers.setCustomTheme.postMessage(type);
    } catch (err) {
      console.log("webkit fail");
    }
  }
}

export function NumberArray(num) {
  var arr = [];
  for (var i = 0; i < num; i++) {
    arr.push({ name: i, id: i });
  }
  return arr;
}

export function SportGameTimers(sport) {
  let timer = 15;
  switch (sport) {
    case "billiards":
    case "cornhole":
    case "tabletennis":
    case "popdarts":
      timer = 15;
      break;
    case "bocce":
      timer = 40;
      break;
    case "darts":
    case "pong":
      timer = 10;
      break;
    case "tableshuffleboard":
      timer = 45;
      break;
    case "horseshoes":
    case "pickleball":
      timer = 20;
      break;
    case "volleyball":
      timer = 25;
      break;
    case "tennis":
      timer = 30;
      break;
    default:
      break;
  }
  return timer;
}

export function findTournamentFilterReducer(state, action) {
  console.log("reducer action: ", action);
  switch (action.type) {
    case "sort": {
      return {
        ...state,
        sort: action.sort,
      };
    }
    case "distance": {
      return {
        ...state,
        distance: action.distance,
      };
    }
    case "lat": {
      return {
        ...state,
        lat: action.lat,
      };
    }
    case "lng": {
      return {
        ...state,
        lng: action.lng,
      };
    }
    case "customLat": {
      return {
        ...state,
        customLat: action.customLat,
      };
    }
    case "customLng": {
      return {
        ...state,
        customLng: action.customLng,
      };
    }
    case "customAddress": {
      return {
        ...state,
        customAddress: action.customAddress,
      };
    }
    case "custom_distance": {
      return {
        ...state,
        customDistance: action.customDistance,
      };
    }
    case "show_custom_distance": {
      return {
        ...state,
        showCustomDistance: action.showCustomDistance,
      };
    }
    case "generation": {
      return {
        ...state,
        generation: action.generation,
      };
    }
    case "favorites": {
      return {
        ...state,
        favorites: action.favorites,
      };
    }
    case "date_start": {
      return {
        ...state,
        dateStart: action.dateStart,
      };
    }
    case "date_end": {
      return {
        ...state,
        dateEnd: action.dateEnd,
      };
    }
    case "mineUpcoming": {
      return {
        ...state,
        mineUpcoming: action.mineUpcoming,
      };
    }
    case "mine_player": {
      return {
        ...state,
        minePlayer: action.minePlayer,
      };
    }
    case "mine_organizer": {
      return {
        ...state,
        mineOrganizer: action.mineOrganizer,
      };
    }
    case "clearFilters": {
      return {
        ...state,
        showCustomDistance: false,
        favorites: false,
        sort: false,
        generation: "All",
        distance: 100,
        resetURLParams: true,
      };
    }
    default:
      break;
  }
  throw Error("Unknown action: " + action.type);
}
export function findClubFilterReducer(state, action) {
  console.log("reducer action: ", action);
  switch (action.type) {
    case "sort": {
      return {
        ...state,
        sort: action.sort,
      };
    }
    case "distance": {
      return {
        ...state,
        distance: action.distance,
      };
    }
    case "lat": {
      return {
        ...state,
        lat: action.lat,
      };
    }
    case "lng": {
      return {
        ...state,
        lng: action.lng,
      };
    }
    case "customLat": {
      return {
        ...state,
        customLat: action.customLat,
      };
    }
    case "customLng": {
      return {
        ...state,
        customLng: action.customLng,
      };
    }
    case "customAddress": {
      return {
        ...state,
        customAddress: action.customAddress,
      };
    }
    case "custom_distance": {
      return {
        ...state,
        customDistance: action.customDistance,
      };
    }
    case "show_custom_distance": {
      return {
        ...state,
        showCustomDistance: action.showCustomDistance,
      };
    }
    case "generation": {
      return {
        ...state,
        generation: action.generation,
      };
    }
    case "favorites": {
      return {
        ...state,
        favorites: action.favorites,
      };
    }
    case "date_start": {
      return {
        ...state,
        dateStart: action.dateStart,
      };
    }
    case "date_end": {
      return {
        ...state,
        dateEnd: action.dateEnd,
      };
    }
    case "mineUpcoming": {
      return {
        ...state,
        mineUpcoming: action.mineUpcoming,
      };
    }
    case "mine_player": {
      return {
        ...state,
        minePlayer: action.minePlayer,
      };
    }
    case "mine_organizer": {
      return {
        ...state,
        mineOrganizer: action.mineOrganizer,
      };
    }
    case "clearFilters": {
      return {
        ...state,
        showCustomDistance: false,
        favorites: false,
        sort: false,
        generation: "All",
        distance: 10000,
        resetURLParams: true,
      };
    }
    default:
      break;
  }
  throw Error("Unknown action: " + action.type);
}

export function FormatRestrictionValues(restrictions, sport) {
  let formattedRestrictions = {};
  let error = null;
  Object.entries(restrictions).forEach((item) => {
    console.log("FormattedRestrictionValues item: ", item);
    formattedRestrictions[item[0]] = {
      ...item[1],
      min: parseFloat(item[1].min),
      max: parseFloat(item[1].max),
    };
    //Check if min/max is less than scoreMagicMinMaxValues, then set an error and disallow form submission
    const r = formattedRestrictions[item[0]];
    const minMaxSport = !["cornhole", "horseshoes"].includes(sport)
      ? "all"
      : sport;
    const minMaxValue = scoreMagicMinMaxValues.find(
      (v) => v.sport === minMaxSport
    )[item[0]];
    console.log("r and minMaxValue: ", r, minMaxValue);
    if (
      r.min < minMaxValue.min ||
      r.min > minMaxValue.max ||
      r.max < minMaxValue.min ||
      r.max > minMaxValue.max
    ) {
      error = `Invalid restriction values for ${item[0].replace(
        "season",
        "Season "
      )}`;
    }
  });
  console.log("FormatRestrictionValues: ", formattedRestrictions);
  if (error) {
    toast.error(error);
    return null;
  } else {
    return formattedRestrictions;
  }
}

export function DetermineEditLimitSpr(localStoragePlayer) {
  //If user has updated limitSpr within last 6 hours, return false, else return true
  const lastUpdatedDate =
    localStorage.hasOwnProperty("limitSpr") &&
    localStorage.getItem("limitSpr") !== null
      ? new Date(localStorage.getItem("limitSpr")).getTime()
      : null;
  const sixHoursAgo = new Date().getTime() - 21600000;
  if (lastUpdatedDate && lastUpdatedDate > sixHoursAgo) {
    //If lastUpdatedDate in ms is greater than sixHoursAgo in ms, it means it's been less than 6 hours since they last updated limitSpr
    // console.log("less than 6 hours ago");
    return false;
  } else {
    // console.log("more than 6 hours ago");
    return true;
  }
}

export function EditLimitSprTime(localStoragePlayer) {
  //Return the hours/minutes until they can edit limitSpr again
  const lastUpdatedDate =
    localStorage.hasOwnProperty("limitSpr") &&
    localStorage.getItem("limitSpr") !== null
      ? new Date(localStorage.getItem("limitSpr")).getTime()
      : null;
  const sixHoursAgo = new Date().getTime() - 21600000;

  const diff = lastUpdatedDate - sixHoursAgo;
  const hours = Math.floor(diff / 3.6e6);
  const minutes = Math.floor((diff % 3.6e6) / 6e4);
  const duration = `${hours}hr ${minutes}min`;

  return duration;
}

// **************************
// Geocoding helper functions
const multipleAddressActions = (dispatch, data) => {
  dispatch({
    type: "customLat",
    customLat: data.lat,
  });
  dispatch({
    type: "customLng",
    customLng: data.lng,
  });
  dispatch({
    type: "customAddress",
    customAddress: data.response
      ? data.response.results[0].formatted_address
      : "Incorrect Zip/Address",
  });
  dispatch({
    type: "custom_distance",
    customDistance: data.location,
  });
};

export async function GeocodeLatLng(state, location, dispatch) {
  let lati = state.lat;
  let long = state.lng;
  console.log("GeocodeLatLng function: ", state, location);
  await fromAddress(location).then(
    (response) => {
      console.log("Geocode response: ", response);
      const { lat, lng } = response.results[0].geometry.location;
      console.log("geocoded lat, lng: ", lat, lng);
      multipleAddressActions(dispatch, { lat, lng, response, location });
      // return { lat: lati, lng: long, response };
    },
    (error) => {
      console.error("geocode.fromAddress error: ", error);
      // return "error";
      multipleAddressActions(dispatch, {
        lat: 0,
        lng: 0,
        response: null,
        location,
      });
    }
  );
}
// End Geocoding helper functions
// **************************

// **************************
// Pre-Reg Functions

function NameCheck(player, formattedName) {
  //Check to see if the player actually has first and last name set. If not, use display name
  console.log("NameCheck player: ", player);
  let name = "";
  if (
    player.userFirstName !== "" &&
    player.userFirstName !== "." &&
    player.userFirstName !== "No First"
  ) {
    name = formattedName;
  } else {
    name = player.userDisplayName;
  }
  console.log("NameCheck name: ", name);
  return name;
}

export function FormatRegistrationTeamNames(
  teammates,
  data,
  player,
  selectedTeam,
  setCustomName,
  type
) {
  // let formattedName = player.userDisplayName;
  console.log("FormatRegistrationTeamNames selectedTeam: ", selectedTeam);
  console.log("FormatRegistrationTeamNames type: ", type);
  console.log("FormatRegistrationTeamNames setCustomName: ", setCustomName);
  console.log("FormatRegistrationTeamNames data: ", data);
  console.log("FormatRegistrationTeamNames player: ", player);
  let formattedName =
    selectedTeam && selectedTeam !== "Create Team"
      ? selectedTeam
      : selectedTeam && selectedTeam === "Create Team"
      ? ""
      : player.userDisplayName
      ? player.userDisplayName
      : player.profileData
      ? player.profileData.displayName
      : "";
  //Determine the team name formatting based on team name type setting
  //If type param === 'single' we need to format for a single player only. Else format ALL teammates in an array and set the team name
  if (data.teamNameType === "Display Names") {
    if (type !== "single") {
      let name =
        selectedTeam === "Create Team"
          ? player.profileData.displayName
          : selectedTeam;
      teammates.forEach((p) => {
        name = `${name}/${p.userDisplayName}`;
      });
      setCustomName(name);
    } else {
      formattedName = `${player.userDisplayName}`;
    }
  } else if (data.teamNameType === "First and Last Name") {
    if (type !== "single") {
      let name =
        selectedTeam === "Create Team"
          ? NameCheck(
              player.profileData,
              `${player.profileData.firstName} ${player.profileData.lastName}`
            )
          : selectedTeam;
      teammates.forEach((p) => {
        name = `${name}/${NameCheck(
          p,
          `${p.userFirstName} ${p.userLastName}`
        )}`;
      });
      setCustomName(name);
    } else {
      formattedName = `${NameCheck(
        player,
        `${player.userFirstName} ${player.userLastName}`
      )}`;
    }
  } else if (data.teamNameType === "First Initial, Last Name") {
    if (type !== "single") {
      let name =
        selectedTeam === "Create Team"
          ? NameCheck(
              player.profileData,
              `${player.profileData.firstName.charAt(0)}. ${
                player.profileData.lastName
              }`
            )
          : selectedTeam;
      teammates.forEach((p) => {
        name = `${name}/${NameCheck(
          p,
          `${p.userFirstName.charAt(0)}. ${p.userLastName}`
        )}`;
      });
      setCustomName(name);
    } else {
      formattedName = `${NameCheck(
        player,
        `${player.userFirstName.charAt(0)}. ${player.userLastName}`
      )}`;
    }
  } else if (data.teamNameType === "First Name, Last Initial") {
    if (type !== "single") {
      let name =
        selectedTeam === "Create Team"
          ? NameCheck(
              player.profileData,
              `${
                player.profileData.firstName
              } ${player.profileData.lastName.charAt(0)}.`
            )
          : selectedTeam;
      teammates.forEach((p) => {
        name = `${name}/${NameCheck(
          p,
          `${p.userFirstName}. ${p.userLastName.charAt(0)}.`
        )}`;
      });
      setCustomName(name);
    } else {
      formattedName = `${NameCheck(
        player,
        `${player.userFirstName}. ${player.userLastName.charAt(0)}.`
      )}`;
    }
  } else if (data.teamNameType === "Custom Team Names" && type !== "single") {
    setCustomName(formattedName);
  }
  console.log("formattedName: ", formattedName);
  return formattedName;
}

// End Pre-Reg Functions
// **************************
